<template>
  <div class="login">
    <div class="logo">
      <img src="../assets/logo.png" alt="" />
    </div>
    <div class="content">
      <div class="content-item">
        <input type="text" placeholder="账号" v-model="loginForm.username" />
        <img src="../assets/header.png" alt="" class="img1" />
      </div>
      <div class="content-item">
        <input
          type="password"
          placeholder="密码"
          v-model="loginForm.password"
        />
        <img src="../assets/password.png" alt="" class="img2" />
      </div>
      <!-- <div class="content-code">
        <div class="code-item">
          <input type="text" placeholder="验证码" v-model="loginForm.code" />
          <img src="../assets/code.png" alt="" class="img2" />
        </div>
        <img :src="codeUrl" alt="" style="fontsize: 0" />
      </div> -->
    </div>
    <div class="sumbit" @click="sumbit">登录</div>
    <div style="margin-top:50px;color:black;font-size:10px" align="center">
       <span>Copyright ©2021 上海市农业发展促进中心  本网站发布的信息未经允许不得转载</span>
       <br/>
       <a href="http://beian.miit.gov.cn/" target="_blank">沪ICP备2021002665号-1</a>
    </div>
  </div>
</template>
<script>
import { login } from "../api/index";
import { setToken } from "../auth";
export default {
  data() {
    return {
      redirect: "",
      imgurl: "../assets/password.png",
      loginForm: {
        username: "",
        password: "",
        code: "",
        uuid: "",
      },
      codeUrl: "",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    // this.getCode();
  },
  methods: {
    // getCode() {
    //   getCodeImg().then((res) => {
    //     this.codeUrl = "data:image/gif;base64," + res.img;
    //     this.loginForm.uuid = res.uuid;
    //   });
    // },
    sumbit() {
      login(
        this.loginForm.username,
        this.loginForm.password
        // this.loginForm.code,
        // this.loginForm.uuid
      )
        .then((res) => {
          setToken(res.token);
          this.$router.push("/");
        })
        .catch(() => {
          // this.getCode();
        });
    },
  },
};
</script>
<style scoped lang="scss">
img {
  display: block;
}
.login {
  width: 100%;
  min-height: 100%;
  background-color: rgba(239, 240, 242, 1);
  padding: 0 0.4rem;
  box-sizing: border-box;
  font-size: 0;
  .logo {
    padding-top: 0.6rem;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .content {
    margin-top: 0.9rem;
    .content-item {
      position: relative;
      margin-bottom: 0.2rem;
      input {
        display: block;
        width: 100%;
        height: 0.5rem;
        padding-left: 0.6rem;
        box-sizing: border-box;
        border-radius: 0.3rem;
        outline: none;
        border: 1px solid #ccc;
        background-color: #fff;
        color: #bebebe;
        font-size: 0.16rem;
      }
      img {
        position: absolute;
        height: 0.5rem;
        top: 10px;
        left: 20px;
      }
      .img1 {
        height: 0.25rem;
        width: 0.25rem;
      }
      .img2 {
        height: 0.3rem;
        width: 0.25rem;
      }
    }
    .content-code {
      position: relative;
      margin-bottom: 0.2rem;
      display: flex;
      height: 0.5rem;
      .code-item {
        width: 70%;
        position: relative;
        input {
          display: block;
          width: 100%;
          height: 0.5rem;
          padding-left: 0.6rem;
          box-sizing: border-box;
          border-radius: 0.3rem;
          outline: none;
          border: 1px solid #ccc;
          background-color: #fff;
          color: #bebebe;
          font-size: 0.16rem;
        }
        img {
          position: absolute;
          height: 0.5rem;
          top: 10px;
          left: 20px;
        }
        .img1 {
          height: 0.25rem;
          width: 0.25rem;
        }
        .img2 {
          height: 0.3rem;
          width: 0.25rem;
        }
      }
      img {
        width: 30%;
        height: 100%;
      }
    }
  }
  .sumbit {
    width: 100%;
    margin-top: 0.5rem;
    height: 0.5rem;
    border-radius: 0.3rem;
    background-color: #0077ff;
    color: #fff;
    text-align: center;
    line-height: 0.5rem;
    font-size: 0.2rem;
  }
}
</style>